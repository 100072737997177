import React from 'react'
import rcmMatterTwoss from './Image/rcmMatterTwoss.jpg'


function RcmFaqs() {
    return (
        <>
            <div className='contact-inner-1'>
                <div className='row'>
                    <div className='col-lg-6' data-aos='fade-left' data-aos-delay='200' data-aos-duration='1500' >
                        <div className='section-title mb-0 rcmOuterFaqsDivSection'>
                            {/* <h6 className='sub-title'>Frequently Asked Questions </h6> */}
                            <h2 className='title rcmFaqsSectionTitle'>Frequently Asked Questions 
                            </h2>
                            <p className='content'>
                                At Tech Matter, we understand the complexities of revenue cycle management in the healthcare industry.
                            </p>
                            <div
                                className='accordion accordion-inner style-3 accordion-icon-left mt-3'
                                id='accordionExample'
                            >
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingOne'>
                                        <button
                                            className='accordion-button rcmFaqsSectionButton'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapseOne'
                                            aria-expanded='true'
                                            aria-controls='collapseOne'
                                        >
                                            Why is RCM important for healthcare?
                                        </button>
                                    </h2>
                                    <div
                                        id='collapseOne'
                                        className='accordion-collapse collapse show'
                                        aria-labelledby='headingOne'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body'>
                                            Effective RCM helps healthcare organizations improve cash flow, reduce claim denials, and optimize revenue.
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingTwo'>
                                        <button
                                            className='accordion-button collapsed rcmFaqsSectionButton'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapseTwo'
                                            aria-expanded='false'
                                            aria-controls='collapseTwo'
                                        >
                                            How to improve healthcare RCM?
                                        </button>
                                    </h2>
                                    <div
                                        id='collapseTwo'
                                        className='accordion-collapse collapse'
                                        aria-labelledby='headingTwo'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body'>
                                            Evaluate RCM, identify issues, partner with RCM provider.
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='accordion-item'>
                                        <h2 className='accordion-header' id='headingThree'>
                                            <button
                                                className='accordion-button collapsed'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target='#collapseThree'
                                                aria-expanded='false'
                                                aria-controls='collapseThree'
                                            >
                                                How can I learn more about revenue cycle management services offered by Tech Matter?
                                            </button>
                                        </h2>
                                        <div
                                            id='collapseThree'
                                            className='accordion-collapse collapse'
                                            aria-labelledby='headingThree'
                                            data-bs-parent='#accordionExample'
                                        >
                                            <div className='accordion-body'>
                                                If you want to enhance your organization's financial performance through revenue cycle management services, contact us or visit our website. Our team provides personalized consultations to comprehend your unique requirements and customize our services accordingly.
                                            </div>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 px-0 whyChooseUsRcmFirstColumn">
                        <img src={rcmMatterTwoss} alt="Revenue Cycle Management" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RcmFaqs