import React from 'react'
import { FaCircleCheck } from "react-icons/fa6";
import workerCompOne from './images/workerCompOne.jpg'
import workerCompTwo from './images/workerCompTwo.jpg'
import workerCompThree from './images/workerCompThree.jpg'
import workCompfaqs from './images/workCompfaqs.jpg'
import './style.css'

function WorkersCompensationAbout() {
    return (
        <>
            <div className="work-process-area-inner-2 pd-bottom-20" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 pd-top-50 pd-bottom-50">
                            <h2 className='thirdSectionHeading'>Learn more about worker’s
                                compensation billing process</h2>
                        </div>
                    </div>
                    <div className="work-process-area-inner-2 ">
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="single-work-process-inner style-2 worker-compensation-why-choose workersCompensSixBoxes">
                                    <div className="details">
                                        <h4 className="mb-3">Verification of employee credentials</h4>
                                        <p className="content">
                                            Our team will verify the injured workers’ employment and injury history, on behalf of the provider.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="single-work-process-inner style-2 worker-compensation-why-choose workersCompensSixBoxes">
                                    <div className="details">
                                        <h4 className="mb-3">Case Verification</h4>
                                        <p className="content">
                                            After verifying the workers’ credentials, we go through the insurance company’s policies regarding the case in detail.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="single-work-process-inner style-2 worker-compensation-why-choose workersCompensSixBoxes">
                                    <div className="details">
                                        <h4 className="mb-3">Medical Documentation</h4>
                                        <p className="content">
                                            Review of all the reports and treatment plans regarding occupational injury or illness.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="work-process-area-inner-2">
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="single-work-process-inner style-2 worker-compensation-why-choose workersCompensSixBoxes">
                                    <div className="details">
                                        <h4 className="mb-3">Medical Coding</h4>
                                        <p className="content">
                                            Based on medical reports and procedures we carry out medical coding, which is updated and checked frequently to avoid errors.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="single-work-process-inner style-2 worker-compensation-why-choose workersCompensSixBoxes">
                                    <div className="details">
                                        <h4 className="mb-3">Review and Submission</h4>
                                        <p className="content">
                                            When verification processes are done, our team carefully scrutinize and review each step from bill submission to reimbursements.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="single-work-process-inner style-2 worker-compensation-why-choose workersCompensSixBoxes">
                                    <div className="details">
                                        <h4 className="mb-3">Timely Follow-up</h4>
                                        <p className="content">
                                            Our team ensure timely follow-up to ensure early reimbursements for the hospital.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="why-choose-us-area why-choose-us-area_2 whyChooseUsWorkCompFirstDiv">
                <div className="container-fluid">
                    <div className="row whyChooseUsWorkCompRow">
                        <div className="col-lg-6 whyChooseUsWorkCompSecondColumn">
                            <h2>
                                Looking to connect with us?
                            </h2>
                            <p className="pt-2">
                                Optimize your revenue cycle with our comprehensive workers’ compensation billing services. RCM Insights is here to assist you with the following benefits:
                            </p>
                            <ul className="single-list-inner style-check style-heading style-check mb-3 ">
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck />
                                    End-to-end revenue cycle and workers’ compensation claims management.
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Guaranteed improved collections and revenue.
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> HIPAA-compliant standards and procedures.
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Efficient patient data and financial handling.
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Superior customer support and services.
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 px-0 whyChooseUsWorkCompFirstColumn">
                            <img src={workerCompOne} alt="workerCompOne" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="why-choose-us-area why-choose-us-area_2 whyChooseUsWorkCompSecondDiv">
                <div className="container-fluid">
                    <div className="row whyChooseUsWorkCompRow">
                        <div className="col-lg-6 px-0 whyChooseUsWorkCompFirstColumn">
                            <img src={workerCompTwo} alt="workerCompTwos" />
                        </div>
                        <div className="col-lg-6 whyChooseUsWorkCompSecondColumn">
                            <h2>
                                Let’s have a live chat about
                                our medical collection services
                            </h2>
                            <p className="pt-2">
                                Our customer service providers will assist you regarding all your queries about collection services. We engage in regular communication with healthcare providers and keep them updated about every aspect of business all through the revenue cycle management.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="why-choose-us-area why-choose-us-area_2 whyChooseUsWorkCompThirdDiv">
                <div className="container-fluid">
                    <div className="row whyChooseUsWorkCompRow">
                        <div className="col-lg-6 whyChooseUsWorkCompSecondColumn">
                            <h2>
                                Outsourcing healthcare collection services: top 5 reasons
                            </h2>
                            <ul className="single-list-inner style-check style-heading style-check mb-3 ">
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck />
                                    Save your time and money
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Risk Prevention and Management
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Professional Expertise by Team
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Lower Rates
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Focus on Core Business
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 px-0 whyChooseUsWorkCompFirstColumn">
                            <img src={workerCompThree} alt="workerCompOne" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="why-choose-us-area why-choose-us-area_2 whyChooseUsWorkCompSecondDiv">
                <div className="container-fluid">
                    <div className="row whyChooseUsWorkCompRow">
                        <div className="col-lg-6 px-0 whyChooseUsWorkCompFirstColumn">
                            <img src={workCompfaqs} alt="workCompfaqs" className='faqsImageTag'/>
                        </div>
                        <div className="col-lg-6 whyChooseUsWorkCompSecondColumn">
                            <h2 className='faqsOuterHeading'>
                                Frequently Asked Questions
                            </h2>
                            <div
                                className='accordion accordion-inner style-3 accordion-icon-left mt-3'
                                id='accordionExample'
                            >
                                <div className='accordion-item'>
                                    <h2 className='accordion-header workComfaqHeading' id='headingOne'>
                                        <button
                                            className='accordion-button workComfaqButton'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapseOne'
                                            aria-expanded='true'
                                            aria-controls='collapseOne'
                                        >
                                            What services does RCM Insights
                                            provide?
                                        </button>
                                    </h2>
                                    <div
                                        id='collapseOne'
                                        className='accordion-collapse collapse '
                                        aria-labelledby='headingOne'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body workComfaqParagraph'>
                                            Our medical collection services include
                                            Medical Billing, Medical Credentialing, Medical Coding, Worker’s Compensation
                                            Collections, Personal Injury Collections and AR Management
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingTwo'>
                                        <button
                                            className='accordion-button collapsed workComfaqButton'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapseTwo'
                                            aria-expanded='false'
                                            aria-controls='collapseTwo'
                                        >
                                            What do Worker’s Compensation
                                            Collection Services include?
                                        </button>
                                    </h2>
                                    <div
                                        id='collapseTwo'
                                        className='accordion-collapse collapse'
                                        aria-labelledby='headingTwo'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body workComfaqParagraph'>
                                            We provide the ultimate solution for your
                                            workers’ compensation billing services which includes effective management of claim
                                            rejections, claim negotiation with the insurer, and reduction in the turnaround time of
                                            outstanding account receivables
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingThree'>
                                        <button
                                            className='accordion-button collapsed workComfaqButton'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapseThree'
                                            aria-expanded='false'
                                            aria-controls='collapseThree'
                                        >
                                            How would I realize my medical
                                            information is secure?
                                        </button>
                                    </h2>
                                    <div
                                        id='collapseThree'
                                        className='accordion-collapse collapse'
                                        aria-labelledby='headingThree'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body workComfaqParagraph'>
                                            Our automated EHR Systems execute all
                                            processes in accordance with HIPAA compliance and policies. Reach out to our customer
                                            support helpline for further information.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkersCompensationAbout