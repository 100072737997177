import React from 'react'
import rcmMatterThree from './Image/rcmMatterThree.jpg'
import './style.css'

function BenefitsRCM() {
    return (
        <>
            {/* <div className="why-choose-area pd-bottom-120 pd-top-120">
                <div className="container">
                    <div className="section-title mt-4 mt-lg-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="title text-center">Benefits of Revenue Cycle Management</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7 col-lg-6 col-md-8 order-lg-last">
                            <img src={rcmMatterTwo} alt="img" />
                        </div>
                        <div className="col-xl-5 col-lg-6 align-self-center align-self-center">
                            <div className="why-choose-us-list mb-2 mt-4 mt-lg-0 benefitLists">
                                <div className="media">
                                    <h4 className="count">01</h4>

                                    <div className="media-body">
                                        <h4 className='benefitHeading'>Access to expert resources and best practices.</h4>

                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-us-list mb-2 benefitLists">
                                <div className="media">
                                    <h4 className="count color-base">02</h4>
                                    <div className="media-body">
                                        <h4 className='benefitHeading'>Cost savings through reduced overhead expenses.</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-us-list mb-2 benefitLists">
                                <div className="media">
                                    <h4 className="count">03</h4>
                                    <div className="media-body">
                                        <h4 className='benefitHeading'>Improved focus on core clinical operations.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-us-list mb-2 benefitLists">
                                <div className="media">
                                    <h4 className="count">04</h4>
                                    <div className="media-body">
                                        <h4 className='benefitHeading'>Enhanced regulatory adherence.</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-us-list mb-2 benefitLists">
                                <div className="media">
                                    <h4 className="count">05</h4>
                                    <div className="media-body">
                                        <h4 className='benefitHeading'>Flexibility to adapt to changing business needs.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="why-choose-us-area why-choose-us-area_2 benefitRcmDiv">
                <div className="container-fluid">
                    <div className="row whyChooseUsRcmRow">
                        <div className="col-lg-6 px-0 whyChooseUsRcmFirstColumn">
                            <img src={rcmMatterThree} alt="Revenue Cycle Management"/>
                        </div>
                        <div className="col-lg-6 BenefitsRcmSecondColumn">
                            <h2 className="title">Benefits of Revenue Cycle Management</h2>
                            <div className="why-choose-us-list mb-2 mt-4 mt-lg-0 benefitLists">
                                <div className="media">
                                    <h4 className="count">01</h4>

                                    <div className="media-body">
                                        <h4 className='benefitHeading'>Access to expert resources and best practices.</h4>

                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-us-list mb-2 benefitLists">
                                <div className="media">
                                    <h4 className="count color-base">02</h4>
                                    <div className="media-body">
                                        <h4 className='benefitHeading'>Cost savings through reduced overhead expenses.</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-us-list mb-2 benefitLists">
                                <div className="media">
                                    <h4 className="count">03</h4>
                                    <div className="media-body">
                                        <h4 className='benefitHeading'>Improved focus on core clinical operations.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-us-list mb-2 benefitLists">
                                <div className="media">
                                    <h4 className="count">04</h4>
                                    <div className="media-body">
                                        <h4 className='benefitHeading'>Enhanced regulatory adherence.</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-us-list mb-2 benefitLists">
                                <div className="media">
                                    <h4 className="count">05</h4>
                                    <div className="media-body">
                                        <h4 className='benefitHeading'>Flexibility to adapt to changing business needs.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default BenefitsRCM