import React from 'react'
import { FaCircleCheck } from "react-icons/fa6";
import piOne from './images/piOne.jpg'
import piTwo from './images/piTwo.jpg'
import piThree from './images/piThree.webp'
import pifaqs from './images/pifaqs.jpg'
import './style.css'

function PersonalInjuryArea() {
    return (
        <>
            {/* Five Div */}

            <div className="work-process-area-inner-2 pd-bottom-80 personalInjuryInnerSecondDiv" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 pd-top-50 pd-bottom-50 piSectionThreeCenter">
                            <h2>RCM Insights - Your Partner
                                for Maximum Reimbursement</h2>
                            <p className='content'>Our experts understand the intricacies of medical lien management and provide ultimate solutions. Say goodbye to challenges in collecting medical debt, our professionals will handle the negotiations, paperwork, and coordination, ensuring you get the financial support you need. Our clients are our priority. We take pride in our recognition for professional expertise, integrity, and hard work in relation to medical debt collection services.

                            </p>
                        </div>
                    </div>
                    <div className="work-process-area-inner-2 piSectionInnerThreeCenter">
                        <div className="row justify-content-center">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="single-work-process-inner style-2 ">
                                    <div className="details">
                                        <h4 className="mb-3">Protecting your rights as a healthcare provider</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="single-work-process-inner style-2 ">
                                    <div className="details">
                                        <h4 className="mb-3">Maximizing the personal injury debt collections</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="single-work-process-inner style-2 ">
                                    <div className="details">
                                        <h4 className="mb-3">Assistance in collecting lost revenue</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="single-work-process-inner style-2 ">
                                    <div className="details">
                                        <h4 className="mb-3">Easier and quicker reimbursements</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* First Div */}
            <div className="why-choose-us-area why-choose-us-area_2 personalInjuryFirstDiv">
                <div className="container-fluid">
                    <div className="row personalInjuryRow">
                        <div className="col-lg-6 personalInjurySecondColumn">
                            <h2>REASONS TO CHOOSE OUR SERVICES:</h2>
                            <p className="pt-2">
                                RCM Insights offers:
                            </p>
                            <ul className="single-list-inner style-check style-heading style-check mb-3 ">
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Maximum compensation for medical services.
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Medical lien management.
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Simplified billing complexities.
                                </li>
                                {/* <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Excellent working ties with state-wide attorneys.
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Cost efficiency and dedicated client servicing.
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Detailed reporting and follow-up.
                                </li> */}
                            </ul>
                        </div>
                        <div className="col-lg-6 px-0 personalInjuryFirstColumn">
                            <img src={piOne} alt="piOne" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Second Div */}
            <div className="why-choose-us-area why-choose-us-area_2 personalInjurySecondDiv">
                <div className="container-fluid">
                    <div className="row personalInjuryRow">
                        <div className="col-lg-6 px-0 personalInjuryFirstColumn">
                            <img src={piTwo} alt="workerCompOne" />
                        </div>
                        <div className="col-lg-6 personalInjurySecondColumn">
                            <h2>Let Your Healthcare Practice Thrive</h2>
                            <p className="pt-2">
                                We provide top personal injury billing and healthcare collection services, ensuring efficient recovery of outstanding receivables. Our dedicated team is committed to smooth lien recoveries.
                            </p>
                            <ul className="single-list-inner style-check style-heading style-check mb-3 ">
                                <li className='worker-comps-list-style secondDivListSvg'><FaCircleCheck /> Spine and Neck Injury </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Fall and Slip Injury
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Dog Bite Injury
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Burn Injury
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Brain Injury
                                </li>
                                <li className='worker-comps-list-style'>
                                    <FaCircleCheck /> Wrongful Death
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            {/* Third Div */}
            <div className="why-choose-us-area why-choose-us-area_2 personalInjuryThirdDiv">
                <div className="container-fluid">
                    <div className="row personalInjuryRow">
                        <div className="col-lg-6 personalInjurySecondColumn">
                            <h2>Some Of Our Features For
                                Personal Injury Debt Collection</h2>
                            <ul className="single-list-inner style-check style-heading style-check mb-3 ">
                                <li className='worker-comps-list-style '>
                                    <FaCircleCheck />A skilled team in personal injury collections, handling claim rejections and risks.
                                </li>
                                <li className='worker-comps-list-style '>
                                    <FaCircleCheck />We use technology to enhance client experience and streamline billing.
                                </li>
                                <li className='worker-comps-list-style '>
                                    <FaCircleCheck />Our team negotiates personal injury collections for maximum reimbursements.
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 px-0 personalInjuryFirstColumn">
                            <img src={piThree} alt="workerCompOne" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Four Div */}
            <div className="why-choose-us-area why-choose-us-area_2 personalInjuryFourDiv">
                <div className="container-fluid">
                    <div className="row personalInjuryRow">
                        <div className="col-lg-6 px-0 personalInjuryFirstColumn">
                            <img src={pifaqs} alt="workerCompOne" />
                        </div>
                        <div className="col-lg-6 faqsMainDiv">
                            <h2 className='faqHeading'>Frequently Asked Questions</h2>
                            <div
                                className='accordion accordion-inner style-3 accordion-icon-left mt-3'
                                id='accordionExample'
                            >
                                <div className='accordion-item'>
                                    <h2 className='accordion-header faqsHeadingSection' id='headingOne'>
                                        <button
                                            className='accordion-button'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapseOne'
                                            aria-expanded='true'
                                            aria-controls='collapseOne'
                                        >
                                            How long will it take to settle
                                            personal injury debt collections?
                                        </button>
                                    </h2>
                                    <div
                                        id='collapseOne'
                                        className='accordion-collapse collapse show'
                                        aria-labelledby='headingOne'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body faqsparagraphSection'>
                                            Our team of dedicated staff will assist you
                                            in getting prompt reimbursements for your healthcare services provided to the patient,
                                            however, it also depends on the nature and severity of the patient’s personal injury
                                            case.
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header faqsHeadingSection' id='headingTwo'>
                                        <button
                                            className='accordion-button collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapseTwo'
                                            aria-expanded='false'
                                            aria-controls='collapseTwo'
                                        >
                                            Why do I need a medical collection
                                            agency for a personal injury case?
                                        </button>
                                    </h2>
                                    <div
                                        id='collapseTwo'
                                        className='accordion-collapse collapse'
                                        aria-labelledby='headingTwo'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body faqsparagraphSection'>
                                            Dealing with your personal injury
                                            collections while managing all the medical tasks and services can be exhausting to you.
                                            Let our company negotiate on your behalf and handle all your debt collections
                                            efficiently
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header faqsHeadingSection' id='headingThree'>
                                        <button
                                            className='accordion-button collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapseThree'
                                            aria-expanded='false'
                                            aria-controls='collapseThree'
                                        >
                                            How does RCM Insights work on medical
                                            collections?
                                        </button>
                                    </h2>
                                    <div
                                        id='collapseThree'
                                        className='accordion-collapse collapse'
                                        aria-labelledby='headingThree'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body faqsparagraphSection'>
                                            Our experts negotiate on your behalf to
                                            clear all your outstanding finances regarding the patient services you provide in
                                            personal injury or workers’ compensation cases. For details and more information contact
                                            our customer support.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default PersonalInjuryArea