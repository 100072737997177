import React from 'react';
const ProjectAreaTwo = () => {
  return (
    <div className="collectibs-area bg-relative pd-top-100">
      <div className="container">
        <div className="section-title text-center">
          <div className="row justify-content-center">
            <div className="col-lg-6 align-self-center">
              <h2 className="title">Make Your Mark On The Blockchain</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="box-shadow bg-relative border-radius-5 mb-4 p-4 pb-0">
              <div className="thumb img-fit-container">
                <img
                  className="border-radius-5"
                  src="assets/img/home-8/27.png"
                  alt="img"
                />
              </div>
              <div className="details p-4">
                <h5 className="mb-2">Crypto Canvas Creator</h5>
                <p className="mb-0">Restaurant Supervisor</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box-shadow bg-relative border-radius-5 mb-4 p-4 pb-0">
              <div className="thumb img-fit-container">
                <img
                  className="border-radius-5"
                  src="assets/img/home-8/28.png"
                  alt="img"
                />
              </div>
              <div className="details p-4">
                <h5 className="mb-2">Crypto Canvas Creator</h5>
                <p className="mb-0">Restaurant Supervisor</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box-shadow bg-relative border-radius-5 mb-4 p-4 pb-0">
              <div className="thumb img-fit-container">
                <img
                  className="border-radius-5"
                  src="assets/img/home-8/29.png"
                  alt="img"
                />
              </div>
              <div className="details p-4">
                <h5 className="mb-2">Crypto Canvas Creator</h5>
                <p className="mb-0">Restaurant Supervisor</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box-shadow bg-relative border-radius-5 mb-4 p-4 pb-0">
              <div className="thumb img-fit-container">
                <img
                  className="border-radius-5"
                  src="assets/img/home-8/30.png"
                  alt="img"
                />
              </div>
              <div className="details p-4">
                <h5 className="mb-2">Crypto Canvas Creator</h5>
                <p className="mb-0">Restaurant Supervisor</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box-shadow bg-relative border-radius-5 mb-4 p-4 pb-0">
              <div className="thumb img-fit-container">
                <img
                  className="border-radius-5"
                  src="assets/img/home-8/31.png"
                  alt="img"
                />
              </div>
              <div className="details p-4">
                <h5 className="mb-2">Crypto Canvas Creator</h5>
                <p className="mb-0">Restaurant Supervisor</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="box-shadow bg-relative border-radius-5 mb-4 p-4 pb-0">
              <div className="thumb img-fit-container">
                <img
                  className="border-radius-5"
                  src="assets/img/home-8/32.png"
                  alt="img"
                />
              </div>
              <div className="details p-4">
                <h5 className="mb-2">Crypto Canvas Creator</h5>
                <p className="mb-0">Restaurant Supervisor</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAreaTwo;
