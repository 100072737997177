import React from 'react'

function VMAWorkProcess() {
  return (
    <>
      <div
        className="work-process-area bg-position-right pd-top-50"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                {/* <h6 className="sub-title-sky-blue">WORK PROCESS</h6> */}
                <h2 className="title">
                  Why Choose Medical Virtual Assistants ?
                </h2>
              </div>
            </div>
          </div>
          <div className="work-process-area-inner-2">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2 text-center VMAWorkProcessDiv">
                  {/* <div className="thumb mb-3">
                    <img src="assets/img/icon/36.svg" alt="img" />
                  </div> */}
                  <div className="details">
                    <h4 className="mb-3">Industry Expertise
                    </h4>
                    <p className="content">
                      Our qualified virtual assistants understand medical practices, ensuring seamless integration with your team.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2 text-center VMAWorkProcessDiv">
                  {/* <div className="thumb mb-3">
                    <img src="assets/img/icon/37.svg" alt="img" />
                  </div> */}
                  <div className="details">
                    <h4 className="mb-3">Customized Solutions
                    </h4>
                    <p className="content">
                      We offer tailored solutions to create a personalized plan that fits your practice’s workflows.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2 text-center VMAWorkProcessDiv">
                  {/* <div className="thumb mb-3">
                    <img src="assets/img/icon/38.svg" alt="img" />
                  </div> */}
                  <div className="details">
                    <h4 className="mb-3">Administrative Support
                    </h4>
                    <p className="content">
                      We provide tailored solutions to create a personalized plan for your practice's workflows.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="work-process-area-inner-2">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2 text-center VMAWorkProcessDivTwo">
                  <div className="thumb mb-3">
                    <img src="assets/img/icon/39.svg" alt="img" />
                  </div>
                  <div className="details">
                    <h4 className="mb-3">Supercharge Patient Communication
                    </h4>
                    <p className="content">
                      Effective patient communication is essential. Our virtual assistants provide appointment reminders, follow-ups, and personalized attention to keep patients informed and engaged.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2 text-center VMAWorkProcessDivTwo">
                  <div className="thumb mb-3">
                    <img src="assets/img/icon/39.svg" alt="img" />
                  </div>
                  <div className="details">
                    <h4 className="mb-3">English And Spanish-Speaking Virtual Assistants</h4>
                    <p className="content">
                      Expand your reach with our bilingual virtual assistants. Our team is fluent in English, Spanish, Hindi, and Urdu, ensuring effective communication with all your patients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default VMAWorkProcess