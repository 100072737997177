import React from "react";
import AboutAreaOne from "../components/AboutAreaOne";
import BannerEight from "../components/BannerEight";
import CaseStudyAreaOne from "../components/CaseStudyAreaOne";
import ContactAreaOne from "../components/ContactAreaOne";
import CounterAreaOne from "../components/CounterAreaOne";
import FaqAreaOne from "../components/FaqAreaOne";
import FooterOne from "../components/FooterOne";
import NavbarTwo from '../components/NavbarTwo';
import PricingAreaOne from "../components/PricingAreaOne";
import ServiceAreaOne from "../components/ServiceAreaOne";
import TeamAreaOne from "../components/TeamAreaOne";
import WorkProcessFour from "../components/WorkProcessFour";
import SliderDetail from "../components/Sliders/SliderDetail";
import AboutSection from "../components/AboutSection/AboutSection";
import WhatWeDo from "../components/WhatWeDo/WhatWeDo";
import NewsletterSection from "../components/NewsletterSection/NewsletterSection";
import LatestNews from "../components/LatestNews/LatestNews";

const HomeOne = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarTwo />

      {/* Banner One */}
      {/* <BannerEight /> */}

      {/* Home Slider */}
      <SliderDetail />

      {/* About Section */}
      <AboutSection />

      {/* What We Do Section */}
      <WhatWeDo />

      {/* NewsLetter Section */}
      <NewsletterSection />

      {/* About Area One */}
      {/* <AboutAreaOne /> */}

      {/* Service Area One */}
      {/* <ServiceAreaOne /> */}

      {/* FAQ Area One */}
      {/* <FaqAreaOne /> */}

      {/* Case Study Area One */}
      {/* <CaseStudyAreaOne /> */}

      {/* Team Area One */}
      {/* <TeamAreaOne /> */}

      {/* Counter Area One */}
      {/* <CounterAreaOne /> */}

      {/* Contact Area One */}
      {/* <ContactAreaOne /> */}

      {/* Pricing Area One */}
      {/* <PricingAreaOne /> */}

      {/* Work Process Four */}
      <WorkProcessFour />

      {/* Blog Area Six */}
      <LatestNews />


      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default HomeOne;
