import React from 'react'
import NavbarTwo from '../../NavbarTwo'
import Breadcrumb from '../../Breadcrumb'
import WorkersCompensationAbout from './WorkersCompensationAbout'
import FooterOne from '../../FooterOne'

function WorkersCompensation() {
    return (
        <>
            <NavbarTwo />
            <Breadcrumb title={'Worker\'s Compensation Collection'} />
            <WorkersCompensationAbout />
            <FooterOne />

        </>
    )
}

export default WorkersCompensation