import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <img
                  className='main-img'
                  src='assets/img/about/about-us-banner-image.webp'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 mb-5'
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h6 className='sub-title'>ABOUT US</h6>
                {/* <h2 className='title'>
                  ABOUT US 
                </h2> */}
                <p className='content mb-3 mb-xl-5'>
                  RCM Insights is the leading provider of Revenue Cycle Management (RCM), Workers' Compensation, and Personal Injury solutions. We are dedicated to helping healthcare providers achieve financial success. Our focus is on simplifying billing processes, improving cash flow, and maximizing reimbursements, allowing you to concentrate on providing quality patient care.
                </p>
                <p className='content mb-3 mb-xl-5'>
                  Our extensive range of services and Medical Virtual Assistant solutions offer comprehensive support for efficiently managing your administrative tasks. From processing claims and managing appeals to follow-up and patient billing, our team ensures that your revenue cycle remains robust and healthy.
                </p>
                <p className='content mb-3 mb-xl-5'>
                  At RCM Insights, we are more than just a service provider. We are your dedicated partner in success. With years of experience, we have built a reputation for excellence and reliability. Whether navigating the complexities of Workers' Compensation or managing the intricacies of Personal Injury claims, we handle the details so you can concentrate on patient care.
                  <br />
                  <br />
                  Together, let's turn your billing challenges into success stories.
                </p>
                {/* <div className='row'>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/2.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Our Ambition</h5>
                        <p>
                          Lorem is Ipsum is simply is design iomyi is text Lorem
                          Ipsum is simply is our busi designer is our country.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/3.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Our Ambition</h5>
                        <p>
                          Lorem is Ipsum is simply is design iomyi is text Lorem
                          Ipsum is simply is our busi designer is our country.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
