import React from 'react'
import NavbarTwo from '../../NavbarTwo'
import Breadcrumb from '../../Breadcrumb'
import WorkProcessVMA from './WorkProcessVMA'
import GetConnected from './GetConnected'
import FooterOne from '../../FooterOne'
import VMAWorkProcess from './VMAWorkProcess'

function VirtualMedicalAssistants() {
    return (
        <>
            <NavbarTwo />
            <Breadcrumb title={'Virtual Medical Assistants'} />
            <WorkProcessVMA/>
            <GetConnected/>
            <VMAWorkProcess/>
            <FooterOne/>
        </>
    )
}

export default VirtualMedicalAssistants