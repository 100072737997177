import React from 'react';
import { Link } from 'react-router-dom';


const WorkProcessFour = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      {/*==================== Work Process Three start ====================*/}
      <div
        className="work-process-area bg-position-right pd-bottom-60"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title">
                {/* <h6 className="sub-title-sky-blue">WORK PROCESS</h6> */}
                <h2 className="title OurServicesTitle">
                  Our Services
                </h2>
              </div>
            </div>
          </div>
          <div className="work-process-area-inner-2">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2 serviceMainDiv">
                  {/* <div className="thumb mb-3">
                    <img src="assets/img/icon/36.svg" alt="img" />
                  </div> */}
                  <div className="details">
                    <h4 className="mb-3">Revenue Cycle Management
                    </h4>
                    <p className="content mb-4">
                      We enhance healthcare finances by improving cash flow and reducing claim denials.
                    </p>
                    <Link to="/revenue-cycle-management" className="explore-link mt-2" onClick={scrollToTop} >Read More</Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2 serviceMainDiv">
                  {/* <div className="thumb mb-3">
                    <img src="assets/img/icon/37.svg" alt="img" />
                  </div> */}
                  <div className="details">
                    <h4 className="mb-3">Workers Compensation
                    </h4>
                    <p className="content mb-4">
                      Our solutions streamline workers' compensation claims for timely reimbursement.
                    </p>
                    <Link to="/workers-compensation" className="explore-link" onClick={scrollToTop} >Read More</Link>

                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2  serviceMainDiv">
                  {/* <div className="thumb mb-3">
                    <img src="assets/img/icon/38.svg" alt="img" />
                  </div> */}
                  <div className="details">
                    <h4 className="mb-3">Personal Injury
                    </h4>
                    <p className="content mb-4">
                      We manage personal injury cases for timely payments and precise documentation.
                    </p>
                    <Link to="/personal-injury" className="explore-link" onClick={scrollToTop} >Read More</Link>

                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2 serviceMainDiv">
                  {/* <div className="thumb mb-3">
                    <img src="assets/img/icon/38.svg" alt="img" />
                  </div> */}
                  <div className="details">
                    <h4 className="mb-3">Medical Virtual Assistant
                    </h4>
                    <p className="content mb-4">
                      Our Medical Virtual Assistants streamline tasks, allowing providers to focus on patients.
                    </p>
                    <Link to="/virtual-medical-assistants" className="explore-link" onClick={scrollToTop} >Read More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* ==================== Work Process Three end ====================*/}
    </>
  );
};

export default WorkProcessFour;
