import React from 'react'
import NavbarTwo from '../../NavbarTwo'
import Breadcrumb from '../../Breadcrumb'
import PersonalInjuryArea from './PersonalInjuryArea'
import FooterOne from '../../FooterOne'


function PersonalInjury() {
    return (
        <>
            <NavbarTwo />
            <Breadcrumb title={'Personal Injury'} />
            <PersonalInjuryArea />
            <FooterOne />
        </>
    )
}

export default PersonalInjury