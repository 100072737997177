import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

function WhatWeDo() {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div className="container what-we-do-section">
                <div className="row">
                    <div className="col-md-2">
                        <h2 className="what-we-do-title">What We Do</h2>
                    </div>
                    <div className="col-md-10">
                        <div className="row what-we-do-row">
                            <div className="col-12 what-we-do-item">
                                <div className="row">
                                    <div className="col-md-2 item-category insight">
                                        Rcm Insights
                                    </div>
                                    <div className="col-md-6 item-title">
                                        Revenue Cycle Management
                                    </div>
                                    <div className="col-md-4 item-description">
                                        <p>Some key benefits of Revenue Cycle Management include:</p>
                                        <ul>
                                            <li>Access to expert resources and best practices.</li>
                                            <li>Cost savings through reduced overhead expenses.</li>
                                            <li>Improved focus on core clinical operations.</li>
                                        </ul>
                                        <Link to="/revenue-cycle-management" className="explore-link" onClick={scrollToTop} >Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 what-we-do-item">
                                <div className="row">
                                    <div className="col-md-2 item-category service">
                                        Rcm Insights
                                    </div>
                                    <div className="col-md-6 item-title">
                                        Workers' Compensation
                                    </div>
                                    <div className="col-md-4 item-description">
                                        <ul>
                                            <li>Optimize your revenue cycle with our comprehensive workers’ compensation billing services.</li>
                                            <li>End-to-end revenue cycle and workers’ compensation claims management.</li>
                                            <li>Guaranteed improved collections and revenue.</li>
                                            <li>HIPAA-compliant standards and procedures.</li>
                                        </ul>
                                        <Link to="/workers-compensation" className="explore-link" onClick={scrollToTop} >Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 what-we-do-item">
                                <div className="row">
                                    <div className="col-md-2 item-category career">
                                        Rcm Insights
                                    </div>
                                    <div className="col-md-6 item-title personalInjuriyTitle">
                                        Personal Injury
                                    </div>
                                    <div className="col-md-4 item-description">
                                        <p>Reason to choose our services:</p>
                                        <ul>
                                            <li>Personal Injury ImageMaximum compensation for your medical services to the patient.</li>
                                            <li>Personal Injury ImageMedical lien management.</li>
                                            <li>Personal Injury ImageTaking care of your billing complexities.</li>
                                        </ul>
                                        <Link to="/personal-injury" className="explore-link" onClick={scrollToTop} >Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 what-we-do-item">
                                <div className="row">
                                    <div className="col-md-2 item-category career">
                                        Rcm Insights
                                    </div>
                                    <div className="col-md-6 item-title">
                                        Medical Virtual Assistant
                                    </div>
                                    <div className="col-md-4 item-description">
                                        <p>Why Choose Medical Virtual Assistants:</p>
                                        <ul>
                                            <li>Industry Expertise</li>
                                            <li>Customized Solutions</li>
                                            <li>Administrative Support</li>
                                            <li>Supercharge Patient Communication</li>
                                        </ul>
                                        <Link to="/virtual-medical-assistants" className="explore-link" onClick={scrollToTop} >Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhatWeDo