import React from 'react'
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function GetConnected() {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    return (
        <> <div
            className="team-area bg-relative pd-top-60"
            style={{ backgroundColor: '#1a3e69' }}
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="section-title text-center ">
                            <h6 className="text-white bg-none mb-3">Are You Ready To Take Your Medical Practice To The Next Level?
                            </h6>
                            <h3 className="title text-white contactNewsLetterSectionHeading">
                                Contact us today and let us show you how our Virtual Assistant Services can transform your operations, optimize efficiency, and enhance patient care. Say hello to a stress-free practice and skyrocketing productivity.
                            </h3>
                            <div className="nav-right-part nav-right-part-desktop align-self-center">
                                <Link className="btn btn-border-base header-right-button-link" onClick={scrollToTop} to="/contact-us">
                                    Get in touch now! <FaPlus />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        </>
    )
}

export default GetConnected