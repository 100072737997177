import React from 'react'
import vmaOne from './images/vmaOne.jpg'
import vmaTwo from './images/vmaTwo.jpg'
import vmaThree from './images/vmaThree.jpg'
import vmaFour from './images/vmaFour.jpg'
import './style.css'


const WorkProcessVMA = () => {

  return (
    <>
      {/*==================== Work Process Three start ====================*/}
      <div className="work-process-area bg-position-right pd-top-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title text-center">
                {/* <h6 className="sub-title-sky-blue">WORK PROCESS</h6> */}
                <h2 className="title">
                  Ultimate Virtual Assistant Solution For Medical Practices
                </h2>
                <p className="content">
                  A medical virtual assistant by RCM Insights, is a skilled professional who provides administrative, operational, and sometimes clinical support to healthcare professionals remotely. They utilize technology and virtual communication tools to perform various tasks, helping streamline workflow, improve efficiency, and enhance the overall functioning of medical practices.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* First Div */}
        <div className="why-choose-us-area why-choose-us-area_2 virtualMedicalAssistantFirstDiv">
          <div className="container-fluid">
            <div className="row whyChooseUsvirtualMedicalAssistantRow">
              <div className="col-lg-6 whyChooseUsvirtualMedicalAssistantSecondColumn">
                <h2>
                  Virtual EMR Services
                </h2>
                <p className="pt-2">
                  RCM Insights provides hassle-free Virtual EMR Services, allowing you to focus on patient care. Our virtual assistant streamlines medical records, reducing paperwork and enhancing efficiency.
                </p>

              </div>
              <div className="col-lg-6 px-0 whyChooseUsvirtualMedicalAssistantFirstColumn">
                <img src={vmaOne} alt="workerCompOne" />
              </div>
            </div>
          </div>
        </div>
        {/* Second Div */}
        <div className="why-choose-us-area why-choose-us-area_2 virtualMedicalAssistantSecondDiv">
          <div className="container-fluid">
            <div className="row whyChooseUsvirtualMedicalAssistantRow">
              <div className="col-lg-6 px-0 whyChooseUsvirtualMedicalAssistantSecondColumn">
                <img src={vmaTwo} alt="workerCompOne" />
              </div>
              <div className="col-lg-6 whyChooseUsvirtualMedicalAssistantSecondColumn">
                <h2>
                  Medical Scribes
                </h2>
                <p className="pt-2">
                  Our Medical Scribes and Virtual Assistants manage patient data collection and record updates, ensuring accurate medication reconciliation. Gain peace of mind and elevate patient care with RCM Insights.


                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Third Div */}
        <div className="why-choose-us-area why-choose-us-area_2 virtualMedicalAssistantThirdDiv">
          <div className="container-fluid">
            <div className="row whyChooseUsvirtualMedicalAssistantRow">
              <div className="col-lg-6 whyChooseUsvirtualMedicalAssistantSecondColumn">
                <h2>
                  Patient Database Management
                </h2>
                <p className="pt-2">
                  Enjoy seamless Patient Database Management as our virtual assistants maintain accurate records. Partner with RCM Insights to reduce paperwork and enhance your focus on patient interactions.


                </p>

              </div>
              <div className="col-lg-6 px-0 whyChooseUsvirtualMedicalAssistantFirstColumn">
                <img src={vmaThree} alt="workerCompOne" />
              </div>
            </div>
          </div>
        </div>
        {/* Four Div */}
        <div className="why-choose-us-area why-choose-us-area_2 virtualMedicalAssistantFourDiv">
          <div className="container-fluid">
            <div className="row whyChooseUsvirtualMedicalAssistantRow">
              <div className="col-lg-6 px-0 whyChooseUsvirtualMedicalAssistantSecondColumn">
                <img src={vmaFour} alt="workerCompOne" />
              </div>
              <div className="col-lg-6 whyChooseUsvirtualMedicalAssistantSecondColumn">
                <h2>Remote Patient Monitoring</h2>
                <p className="pt-2">
                  Our Remote Patient Monitoring service connects you to your health, allowing effortless tracking of vital signs and medication adherence. With RCM Insights, healthcare is accessible and proactive.




                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process Three end ====================*/}
    </>
  );
};

export default WorkProcessVMA;
