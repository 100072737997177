import React from 'react';
import { Link } from 'react-router-dom';


function AboutSection() {
    return (
        <>
            <div className="why-choose-us-area why-choose-us-area_2 pd-top-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p>
                                We are the leading provider of Revenue Cycle Management, Workers Compensation, and Personal Injury solutions. With our Medical Virtual Assistant services and specialized expertise, we deliver comprehensive support to healthcare professionals and organizations across every sector, ensuring efficiency and growth in every aspect of medical administration.
                            </p>
                            <Link className="btn btn-border-base mb-3 " to="/">
                                Learn More
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutSection