import React from 'react'
import rcmMatterOne from './Image/rcmMatterOne.jpg'
import './style.css'

function WhyChooseUsRCM() {
    return (
        <>
            <div className="why-choose-us-area why-choose-us-area_2 whyChooseUsRcmDiv">
                <div className="container-fluid">
                    <div className="row whyChooseUsRcmRow">
                        <div className="col-lg-6 px-0 whyChooseUsRcmFirstColumn">
                            <img src={rcmMatterOne} alt="Revenue Cycle Management" />
                        </div>
                        <div className="col-lg-6 whyChooseUsRcmSecondColumn">
                            <h2>
                                Maximizing Revenue through Effective Revenue Cycle Management Services
                            </h2>
                            <p className="pt-2">
                                Welcome to our comprehensive revenue cycle management services! In today’s healthcare landscape, providers must optimize revenue while ensuring regulatory compliance. Our tailored RCM services streamline financial processes, allowing you to focus on delivering quality patient care.
                            </p>

                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}

export default WhyChooseUsRCM