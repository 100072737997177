import React from 'react'
import NavbarTwo from '../../NavbarTwo'
import Breadcrumb from '../../../components/Breadcrumb';
import FooterOne from '../../FooterOne';
import WhyChooseUsRCM from './WhyChooseUsRCM';
import BenefitsRCM from './BenefitsRCM';
import RcmFaqs from './RcmFaqs';

function RevenueManagementCycle() {
    return (
        <>
            <NavbarTwo />
            <Breadcrumb title={'Revenue Management Cycle'} />
            <WhyChooseUsRCM />
            <RcmFaqs />
            <BenefitsRCM />
            <FooterOne />

        </>
    )
}

export default RevenueManagementCycle