import React from "react";

const ContactAreaTwo = () => {
  return (
    <>
      {/* ========================= contact Area Two start =========================*/}
      <div className='contact-area'>
        <div className='container'>
          <div className='contact-inner-1'>
            <div className='row'>
              {/* <div
                className='col-lg-8'
                data-aos='fade-right'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <img className='w-100' src='assets/img/bg/9.png' alt='img' />
              </div> */}
              <div
                className='col-lg-12 '
                data-aos='fade-left'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <div className='section-title mb-0'>
                  <h6 className='sub-title'>Frequently Asked Questions </h6>
                  <h2 className='title'>About Revenue Cycle Management Solutions
                  </h2>
                  <p className='content'>
                    At Tech Matter, we understand the complexities of revenue cycle management in the healthcare industry.
                  </p>
                  <div
                    className='accordion accordion-inner style-3 accordion-icon-left mt-3'
                    id='accordionExample'
                  >
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingOne'>
                        <button
                          className='accordion-button'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseOne'
                          aria-expanded='true'
                          aria-controls='collapseOne'
                        >
                          Why is revenue cycle management important for healthcare organizations?
                        </button>
                      </h2>
                      <div
                        id='collapseOne'
                        className='accordion-collapse collapse show'
                        aria-labelledby='headingOne'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                          Healthcare organizations can optimize revenue streams by streamlining administrative processes, minimizing claim denials, and accelerating reimbursement cycles with effective revenue cycle management for financial stability.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingTwo'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseTwo'
                          aria-expanded='false'
                          aria-controls='collapseTwo'
                        >
                          How can healthcare organizations get started with improving their revenue cycle management processes?
                        </button>
                      </h2>
                      <div
                        id='collapseTwo'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingTwo'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                          Healthcare organizations should evaluate their revenue cycle processes, pinpoint areas that need improvement, and partner with a reputable RCM provider to optimize their financial performance.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingThree'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseThree'
                          aria-expanded='false'
                          aria-controls='collapseThree'
                        >
                          How can I learn more about revenue cycle management services offered by Tech Matter?
                        </button>
                      </h2>
                      <div
                        id='collapseThree'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingThree'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                          If you want to enhance your organization's financial performance through revenue cycle management services, contact us or visit our website. Our team provides personalized consultations to comprehend your unique requirements and customize our services accordingly.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*========================= contact-inner Two end =========================*/}
    </>
  );
};

export default ContactAreaTwo;
