import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SellerOne = () => {
  return (
    <>
      {/* saller area start */}
      <div className="saller-area bg-relative pd-top-100">
        <div className="container">
          <div className="section-title">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <h2 className="title">Best Sellers</h2>
              </div>
              <div className="col-md-6 text-lg-end d-lg-block d-none">
                <Link className="btn btn-base-pink" to="/about">
                  All Seller <FaChevronRight className="mt-4" />
                </Link>
              </div>
            </div>
          </div>
          <div className="row saller-area-inner">
            <div className="col-lg-4 col-md-6 col-12 ">
              <div className="media single-saller-inner">
                <div className="media-left  pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/34.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@Jeromeell</h6>
                  <p className="mb-0">$102</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className=" media single-saller-inner">
                <div className="media-left pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/35.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@MarvinKinney</h6>
                  <p className="mb-0">$320</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 ">
              <div className=" media single-saller-inner">
                <div className="media-left pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/36.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@kavendbre</h6>
                  <p className="mb-0">$5045</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className=" media single-saller-inner">
                <div className="media-left  pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/37.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@kadervar</h6>
                  <p className="mb-0">$52</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 ">
              <div className=" media single-saller-inner">
                <div className="media-left pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/38.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@MarvinKinney</h6>
                  <p className="mb-0">$105</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="media single-saller-inner">
                <div className="media-left pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/39.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@MarvinKinney</h6>
                  <p className="mb-0">$5045</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className=" media single-saller-inner">
                <div className="media-left pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/40.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@gfugney</h6>
                  <p className="mb-0">$5045</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className=" media single-saller-inner">
                <div className="media-left pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/41.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@Guyawkins</h6>
                  <p className="mb-0">$532</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 ">
              <div className=" media single-saller-inner">
                <div className="media-left  pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/42.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@ArlenecCoy</h6>
                  <p className="mb-0">$98</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className=" media single-saller-inner">
                <div className="media-left pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/43.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@MarvinKinney</h6>
                  <p className="mb-0">$5045</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 ">
              <div className=" media single-saller-inner">
                <div className="media-left pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/44.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@ArlenecCoy</h6>
                  <p className="mb-0">$99</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className=" media single-saller-inner">
                <div className="media-left pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/45.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@Eleanorena</h6>
                  <p className="mb-0">$5045</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className=" media single-saller-inner">
                <div className="media-left  pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/46.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@MarvinKinney</h6>
                  <p className="mb-0">$5045</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 ">
              <div className=" media single-saller-inner">
                <div className="media-left pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/33.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@Darleneob</h6>
                  <p className="mb-0">$750</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 ">
              <div className=" media single-saller-inner">
                <div className="media-left  pe-3">
                  <div className="img-file">
                    <img
                      className="border-radius-50"
                      src="assets/img/home-8/33.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="media-body align-self-center">
                  <h6 className="mb-1">@WRobertfox</h6>
                  <p className="mb-0">$928</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* saller area end */}
    </>
  );
};

export default SellerOne;
