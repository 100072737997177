import React from 'react';
// import { FaPlus } from 'react-icons/fa';
// import { Link } from 'react-router-dom';

const WhyChooseTwo = () => {
  return (
    <>
      {/* start why choose us */}
      <div className="why-choose-us-area why-choose-us-area_2 pd-top-120">
        <div className="container">
          {/* <div className="row justify-content-center">
            <div className="col-lg-5 col-md-6">
              <div className="section-title mb-0 text-center">
                <h6 className="sub-title">Why Choose Us</h6>
                <h2 className="title">Empowering Investors Worldwide</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className=" bg-relative border-radius-5 mb-4 p-4 pb-0 text-center">
                <div className="thumb">
                  <img
                    className="border-radius-5"
                    src="assets/img/home-11/why-1.png"
                    alt="img"
                  />
                </div>
                <div className="details p-3">
                  <h5 className="mb-2">24/7 Support</h5>
                  <p className="mb-0 ">
                    Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum
                    is simply is our busi Lorem is Ipsum is is
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" bg-relative border-radius-5 mb-4 p-4 pb-0 text-center">
                <div className="thumb">
                  <img
                    className="border-radius-5"
                    src="assets/img/home-11/why-2.png"
                    alt="img"
                  />
                </div>
                <div className="details p-3">
                  <h5 className="mb-2">Free Withdraw</h5>
                  <p className="mb-0">
                    Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum
                    is simply is our busi Lorem is Ipsum is is
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" bg-relative border-radius-5 mb-4 p-4 pb-0 text-center">
                <div className="thumb">
                  <img
                    className="border-radius-5"
                    src="assets/img/home-11/why-3.png"
                    alt="img"
                  />
                </div>
                <div className="details p-3">
                  <h5 className="mb-2">Crypto Support</h5>
                  <p className="mb-0">
                    Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum
                    is simply is our busi Lorem is Ipsum is is
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-6">
              <img src="assets/img/home-11/revenue-cycle-management-image-one.jpg" alt="Revenue Cycle Management" />
            </div>
            <div className="col-lg-6">
              <h2>
                Maximizing Revenue through Effective Revenue Cycle Management Services
              </h2>
              <p className="pt-2">
                Welcome to our page on comprehensive revenue cycle management services! In today's rapidly evolving healthcare landscape, healthcare providers must optimize revenue streams while ensuring compliance with regulatory standards. Our customized revenue cycle management services are designed to streamline your organization's financial processes, allowing you to focus on providing high-quality patient care.
              </p>
              <p className="pt-2">
                Revenue Cycle Management (RCM) involves the financial processes of healthcare organizations that manage claims processing, payment, and revenue generation, including patient registration, insurance verification, coding, billing, and collections.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* end why choose us */}
    </>
  );
};

export default WhyChooseTwo;
